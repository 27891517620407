

<script>


export default {
  components: {  },
  data() {
    return {
      items:[],
      preVersion: null,
      selectedItem: null,
      newItem:{
        key:null,
        value: null
      }
    }
  },
  props: {
    values: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  watch:{
    values: {
      handler: function () {
        this.updateDataFromProp();
      }
    }
  },
  created(){
   this.updateDataFromProp();
  },
  methods:{
    updateDataFromProp(){
      const keys = Object.keys(this.values);
      let v = [];
      keys.forEach(key => {
        v.push({key: key, value: this.values[key]}) ;
      });
      v.sort((a, b) => a.key.localeCompare(b.key));
      this.items = v;
    },
    addNewItem () {
      if(this.newItem.key){
        this.items.push({key: this.newItem.key, value: this.newItem.value });
        this.newItem = {
          key:null,
          value: null
        };
       this.recalculate();
       this.onChange();
      }
    },
    editItem (item){
      this.preVersion = {...item};
      this.selectedItem = item;
    },
    endEdit(){
      this.selectedItem.key = this.preVersion.key;
      this.selectedItem.value = this.preVersion.value;
       this.selectedItem = null;
    },
    saveChanges(){
      if(!this.selectedItem.key)
        return;
      this.selectedItem = null;
      this.recalculate();
      this.onChange();
    
    },
    recalculate(){
      let v = [...this.items];
      v.sort((a, b) => a.key.localeCompare(b.key));
      this.items = v;
     
    },
    onChange(){
      let o = {};
      this.items.forEach(item => {
        o[item.key] = item.value;  
      });
       this.$emit("change", o);
    },
    deleteItem(item) {
      this.items = [... this.items.filter(x => x !== item)]
       this.onChange();
    }
  }
}
</script>


<template>
  <!-- start page title -->
  
    <div class="row" style="max-height: 345px;  overflow-y:auto;">
      <div class="col-12">
        <div class="table-responsive">
          <table
            class="table table-centered table-sm datatable dt-responsive nowrap"
              style="border-collapse: collapse; border-spacing: 0; width: 100%; position: relative;"
          >
            <thead class="thead-light">
              <tr>
                <th style="width: 30px;max-width:30px;position: sticky;top: 0;">
                  <a
                    style="margin-left:5px"
                    href="javascript:void(0);"
                    class="text-primary"
                    :disable="!newItem.key"
                    v-b-tooltip.hover
                    @click="addNewItem()"
                  >
                    <i class="fa fa-plus font-size-18"></i>
                  </a>
                </th>
                <th style="position: sticky;top: 0;"> <input type="text" class="form-control input-sm" v-model="newItem.key" /></th>
                <th style="position: sticky;top: 0;"> <input type="text" class="form-control input-sm" v-model="newItem.value"/></th>
                <th style="width: 30px;max-width:30px;position: sticky;top: 0;"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="items.length === 0">
                <td colspan="100">
                  <span class="text-muted">{{$t("common.noRecordsFound")}}</span>
                </td>
              </tr>
            
              <tr v-for="(item, index) in items" :key="index">
                <td>
                  <a
                    v-if="selectedItem !== item"
                    href="javascript:void(0);"
                    class="text-primary"
                    v-b-tooltip.hover
                    @click="editItem(item)"
                  >
                    <i class="mdi mdi-pencil font-size-18"></i>
                  </a>
                  <a
                    v-if="selectedItem === item"
                    href="javascript:void(0);"
                    class="text-primary"
                    v-b-tooltip.hover
                    :disabled="!selectedItem.key"
                    @click="saveChanges()"
                  >
                    <i class="fa fa-check font-size-18"></i>
                  </a>
                </td> 
                <td>
                  <span v-if="selectedItem !== item">{{item.key}}</span>
                  <input v-if="selectedItem === item" type="text" class="form-control input-sm" v-model="selectedItem.key"/>
                </td>
                <td>
                  <span  v-if="selectedItem !== item">{{item.value}}</span>
                  <input v-if="selectedItem === item" type="text" class="form-control input-sm" v-model="selectedItem.value"/>
                </td>
                <td>
                    <a
                    v-if="selectedItem === item"
                    href="javascript:void(0);"
                    class="mr-3 text-primary"
                    v-b-tooltip.hover
                    @click="endEdit()"
                  >
                    <i class="fa fa-times font-size-18"></i>
                  </a>
                  <a
                    v-if="!selectedItem"
                    href="javascript:void(0);"
                    class="mr-3 text-danger"
                    title="Delete"
                    @click="deleteItem(item)"
                  >
                    <i class="fa fa-times font-size-18"></i>
                  </a>
                </td> 
              </tr>
            </tbody>
          </table>
        </div>
      
      </div>
    </div>

  <!-- end page title -->
</template>
